import Component from '../classes/Component';
import IMask from 'imask';
import { $dom } from '../helpers/dom';

const { getAll } = $dom;

const codeMaskSelector = '.js-code-mask';

export default function codeMask() {

  return new Component({
    name: 'codeMask',
    requiredSelector: codeMaskSelector,

    onCreate() {
      this.maskOptions = {
        mask: '000-000',
        // definitions: {
        //   X: {
        //     mask: '0',
        //     displayChar: 'X',
        //     placeholderChar: '#',
        //   },
        // },
        // lazy: false,
        // overwrite: 'shift',
      };

      this.masks = [];

      this.masks = getAll(codeMaskSelector).map(field => IMask(field, this.maskOptions));

    },
    onInit() {

    },
    onDestroy() {
      this.masks = this.masks.filter(mask => {
        mask.destroy && mask.destroy();
        return false
      });
    }
  })
}