import AirDatepicker from 'air-datepicker';
import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import variables from '../variables';

const { get, getAll, addClass, removeClass, hasClass } = $dom;

const calendarSelector = '.js-calendar';

const initialInputAgeSelector = '.js-initial-age';
const initialInputDateOfBirthSelector = '.js-initial-date-of-birth';
const initialAgeContentSelector = '.js-initial-age-content';

export function calendarFunction() {

  function getAgeFromBirthDate(birthDateString) {
    const today = new Date();

    const date = birthDateString.split('.');

    const birthDate = new Date(
      date[2],
      date[1] - 1,
      date[0]
    );

    let age = today.getFullYear() - birthDate.getFullYear();

    if (today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return new Component({
    name: 'calendarFunction',
    requiredTargets: calendarSelector,
    onCreate() { },
    onInit() {

      if (get(initialInputDateOfBirthSelector) instanceof HTMLElement) {

        if (get(initialInputDateOfBirthSelector).value != '') {
          console.log('date of birth is not empty');
          get(initialInputAgeSelector).value = getAgeFromBirthDate(get(initialInputDateOfBirthSelector).value);
        } else {
          console.log('date of birth is empty');
        }
      }

      getAll(initialInputAgeSelector).forEach(element => {
        
        if (element.value >= 14 && element.value < 18) {

          removeClass(get(initialAgeContentSelector, element.parentNode.parentNode.parentNode), 'd-none');
        } else {
          addClass(get(initialAgeContentSelector, element.parentNode.parentNode.parentNode), 'd-none');
        }

      })

      getAll(calendarSelector).forEach(element => {
        new AirDatepicker(element, {
          autoClose: true,
          
          dateFormat(date) {
            return date.toLocaleString('ru', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            });
          },

          onSelect: (event) => {

            currentPage.components.formValidation.checkField(event.datepicker.$el.parentNode);
            // removeClass(event.datepicker.$el.parentNode, variables.classNames.invalid);

            setTimeout(() => {
              removeClass(event.datepicker.$el.parentNode, variables.classNames.invalid);
            }, 1);

            console.log(event.datepicker.$el.parentNode);

            if (!hasClass(event.datepicker.$el, 'js-initial-date-of-birth')) return;

            const ageContent = get(initialAgeContentSelector, event.datepicker.$el.parentNode.parentNode.parentNode);
            const calculatedAge = getAgeFromBirthDate(event.formattedDate);

            get(initialInputAgeSelector, event.datepicker.$el.parentNode.parentNode.parentNode).value = calculatedAge;

            if (calculatedAge >= 14 && calculatedAge < 18) {

              removeClass(ageContent, 'd-none');
            } else {
              addClass(ageContent, 'd-none');
            }
          }
        })
      });
    },
    onDestroy() { }
  })
}