import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';
import variables from '../variables';
import FormValidator from '@yaireo/validator'
import { isElement } from '../helpers/_utilities';

const {
  callAll,
  attr,
  get,
  getAll,
  remove,
  addClass,
  removeClass,
  exist
} = $dom;

const { formValidationMessages } = variables;
const
  formSelector = '.js-form-validate',
  formGroupSelector = '.js-group-validate',
  formFieldSelector = '.js-field-validate',
  notifyClassName = 'form__group-notify',
  needValidateClassName = 'is-need-validate'
  ;

const { active } = variables.classNames;

const perosnalSelector = '.js-personal';
const perosnalItemSelector = '.js-personal-item';
const perosnalItemFormSelector = '.js-personal-item-form';
const perosnalItemFormButtonSelector = '.js-personal-item-form-button';
const perosnalItemFormRegistrSelector = '.js-personal-item-form-registr-button';
const perosnalItemFormCodeSelector = '.js-personal-item-form-button-code';

const personalToRegistrationSelector = '.js-personal-to-registration';
const personalToSmsSelector = '.js-personal-to-sms';
const personalSendedNumberSelector = '.js-personal-sended-number';

const stepsSelector = '.js-steps';
const stepsItemSelector = '.js-steps-item';

const initialListSelector = '.js-initial-list';
const initialItemSelector = '.js-initial-item';

const dataStage = 'data-stage';
const dataStep = 'data-step';
const dataToStep = 'data-to-step';

export function formValidation() {

  function clearItems() {
    getAll(perosnalItemSelector).forEach(element => {
      removeClass(element, active);
    });
  }

  function onlyNumbers() {
    this.value = this.value.replace(/\D+/g, '');
  }

  function changeStep(step) {
    getAll(initialItemSelector).forEach(element => {
      removeClass(element, 'is-current');
    })

    if (step == 'profile') {
      get(stepsSelector).style.display = 'none';
    }

    const nextItem = getAll(initialItemSelector).filter(element => attr(element, dataStep) == step)[0];

    addClass(nextItem, 'is-current')

    const nextStep = getAll(stepsItemSelector).filter(element => attr(element, dataStep) == step)[0]

    addClass(nextStep, 'is-fulfilled');
  }

  return new Component({
    name: 'formValidation',
    requiredTargets: formSelector,
    onCreate() {
      this.validator = null;
    },
    onInit() {

      // -------------

      // changeStep('profile');

      // -------------

      let currentForm = null;
      let submitButton = null;
      let errorElement = null;
      let loaderElement = null;

      this.validator = new FormValidator({
        classes: {
          item: formGroupSelector.replace('.', ''),
          bad: variables.classNames.invalid,
          alert: notifyClassName
        },
        texts: formValidationMessages
      });

      attr(formSelector, 'novalidate', '');

      this.showLoader = (buttonEl, show = true) => {

        if (buttonEl instanceof HTMLElement) {
          if (show) {
            loaderElement = document.createElement("div");

            loaderElement.classList.add('spinner-border')
            loaderElement.setAttribute('role', 'status');

            loaderElement.innerHTML = '  <span class="visually-hidden"></span>';

            buttonEl.appendChild(loaderElement);
            addClass(buttonEl, 'is-loading')
          } else {
            // buttonEl.remove(loaderElement);
            loaderElement.remove();
            removeClass(buttonEl, 'is-loading')
          }
        }
      }

      this.checkField = formGroup => {
        if (formGroup instanceof Event) {
          const { target } = formGroup;

          if (isElement(target)) formGroup = target.closest(formGroupSelector)
        }

        const field = get(formFieldSelector, formGroup);

        if (isElement(field)) {
          const { valid, error } = this.validator.checkField(field);

          return valid;
        }
      };

      this.handleForm = event => {
        console.log('handleForm');
        const form = event.target.closest(formSelector);

        //event.preventDefault();

        let step = null;

        if (get('.initial__item.is-current button[type="submit"]', form) instanceof HTMLElement) {
          step = attr(get('.initial__item.is-current button[type="submit"]', form), dataToStep);
        }

        callAll(formGroupSelector, this.checkField, form);
        let flag = location.hostname  == 'shum-dev1.nm42.net';
        flag = false;
        console.log(!this.validator.checkAll(form).valid ,flag,(!this.validator.checkAll(form).valid && !flag));
        if (!this.validator.checkAll(form).valid && !flag) {
          //console.log('валидация отменена');
          event.preventDefault();

          currentForm = form;
          submitButton = get('button[type="submit"]', form);

          if (get('.initial__item.is-current button[type="submit"]', form) instanceof HTMLElement) {
            submitButton = get('.initial__item.is-current button[type="submit"]', form);
          }

          if (get('.initial__item.is-current', form) instanceof HTMLElement) {
            currentForm = get('.initial__item.is-current .personal__form', form);
          }

          if (currentForm instanceof HTMLElement) {

            if (get('.form-error-text', currentForm) instanceof HTMLElement) {

              return;
            }

            errorElement = document.createElement("p");

            errorElement.classList.add('form-error-text')

            errorElement.innerHTML = 'Ошибка при отправке формы, данные не заполненны полностью';

            currentForm.appendChild(errorElement);
          }

          console.log('not ok 1');

        } else {

          console.log('ok');

          if (currentForm instanceof HTMLElement) {
            errorElement.remove();
          }

          this.showLoader(submitButton, true);

          setTimeout(() => {
            this.showLoader(submitButton, false);
          }, 1000);

          if(step != 'final') {
            if(!step){

            }else {
              event.preventDefault();

              changeStep(step);
            }
          } else {

            console.log('dont changeStep');
            //changeStep(step);


            // changeStep(step); - переход на следующий экран

            // Происходит сабмит

          }

        }
      };

      this.checkFirstStage = event => {
        const target = event.target.closest(perosnalItemSelector);

        if (event.target.closest(perosnalItemFormButtonSelector)) {

          const numberInput = get('.js-phone-mask', target).parentNode;
          const checkboxInput = get('input[type="checkbox"]', target).parentNode;
          const submitButton = get('.js-personal-item-form-button', target);

          this.checkField(numberInput)
          this.checkField(checkboxInput)

          if (this.checkField(numberInput) && this.checkField(checkboxInput)) {
            console.log('ok stage 1', numberInput);

            this.showLoader(submitButton, true);
            setTimeout(() => {
              this.showLoader(submitButton, false);
            }, 2000);
            window.codeResult = { 'status': 'success', 'data': { 'success': true } };
            if (typeof BX !== "undefined") { // на домене
              console.log('BX');
              BX.ajax.runComponentAction('custom:auth.phone', 'sms', {
                mode: 'class',
                data: {
                  post: {
                    'phone': get('.js-phone-mask', target).value
                  }
                }
              }).then(function (response) {
                if (response.status == 'success') {
                  if (response.data.success) {
                    clearItems();
                    addClass(get(`${perosnalItemSelector}[data-stage='sms']`), active);
                    const numberValue = get('.js-phone-mask', target).value;
                    get(`${personalSendedNumberSelector} span`).innerHTML = `${numberValue.substring(0, 3)} *** *** ** ${numberValue.substring(16, numberValue.length)}`;

                    // get(`${perosnalItemSelector}[data-stage='sms'] input`).value = numberValue.substring(0, 12);

                  } else {
                    if (response.data.error) {
                      // window.APP.authentication.alertFrom('.js-reg-form', response.data.error);
                      alert(response.data.error);
                    } else {
                      //window.APP.authentication.alertFrom('.js-reg-form', 'Ошибка отправки или превышен лимит отправленных смс');
                      alert('Ошибка отправки или превышен лимит отправленных смс');
                    }
                  }
                } else {
                  alert('Ошибка отправки или превышен лимит отправленных смс');
                }

              });
            } else {
              clearItems();
              addClass(get(`${perosnalItemSelector}[data-stage='sms']`), active);
              const numberValue = get('.js-phone-mask', target).value;
              get(`${personalSendedNumberSelector} span`).innerHTML = `${numberValue.substring(0, 3)} *** *** ** ${numberValue.substring(16, numberValue.length)}`;

              // get(`${perosnalItemSelector}[data-stage='sms'] input`).value = numberValue.substring(0, 12);
            }
          }
        }
      }

      this.toRegistration = () => {
        clearItems();

        addClass(get(`${perosnalItemSelector}[data-stage='register']`), active);
      }
      this.toSms = () => {
        clearItems();

        addClass(get(`${perosnalItemSelector}[data-stage='initial']`), active);
      }

      this.checkRegistrStage = event => {
        const target = event.target.closest(perosnalItemSelector);

        if (event.target.closest(perosnalItemFormRegistrSelector)) {

          const emailInput = get('input[type="email"]', target).parentNode;
          const passwordInput = get('input[type="password"]', target).parentNode;
          const checkboxInput = get('input[type="checkbox"]', target).parentNode;
          const submitButton = get('button[type="submit"]', target);

          // console.log(submitButton);

          this.checkField(emailInput)
          this.checkField(passwordInput)
          this.checkField(checkboxInput)

          if (this.checkField(emailInput) && this.checkField(passwordInput) && this.checkField(checkboxInput)) {
            console.log('ok stage 2');

            this.showLoader(submitButton, true);
            setTimeout(() => {
              this.showLoader(submitButton, false);
            }, 1000);
            if (typeof BX !== "undefined") { // на домене
              var request = BX.ajax.runComponentAction('custom:auth.phone', 'authorize', {
                mode: 'class',
                data: {
                  post: {
                    'email': get('input[type="email"]', target).value,
                    'password': get('input[type="password"]', target).value
                  }
                }
              });

              request.then(function (response) {
                //console.log(response);
                if (response.status == 'success') {
                  if (response.data.code == 'error') {
                    //$(document.forms.auth).find('.error').show().html(response.data.errors);
                    alert(response.data.errors);
                  } else {
                    if (!!response.data.redirect) {
                      location = response.data.redirect;

                      this.showLoader(submitButton, false);
                    } else {
                      clearItems();
                      addClass(get(`${perosnalItemSelector}[data-stage='finish']`), active);

                      this.showLoader(submitButton, false);
                    }
                  }


                }
              }).catch(function (r) {
                $(document.forms.auth).find('.error').html('Ошибка авторизации');

                this.showLoader(submitButton, false);
              });
            } else {
              clearItems();
              addClass(get(`${perosnalItemSelector}[data-stage='finish']`), active);

              this.showLoader(submitButton, false);
            }

          }
        }
      }

      this.checkCodeStage = event => {
        const target = event.target.closest(perosnalItemSelector);

        if (event.target.closest(perosnalItemFormCodeSelector)) {

          const numberInput = get('.js-input-number', target).parentNode;
          const code = get('.js-input-number', target).value;

          const submitButton = get('button[type="submit"]', target);

          this.checkField(numberInput)

          if (this.checkField(numberInput)) {

            console.log('ok stage code');

            this.showLoader(submitButton, true);
            setTimeout(() => {
              this.showLoader(submitButton, false);
            }, 1000);
            // ----------------
            
            // this.showLoader(submitButton, false); - чтобы убрать лоадер;
            
            // ----------------

            if (typeof BX !== "undefined") { // на домене
              BX.ajax.runComponentAction('custom:auth.phone', 'verifica', {
                mode: 'class',
                data: {
                  post: {
                    code: code,
                  }
                },
              }).then(function (response) {
                if (response.status === 'success' && response.data) {
                  if (response.data.success) {
                    window.location.href = '/personal/?newUser=Y';
                  } else {
                    //window.APP.authentication.alertFrom('.js-reg-code-form', response.data.error);
                    alert(response.data.error);

                    this.showLoader(submitButton, false);
                  }
                }
                else {
                  //window.APP.authentication.alertFrom('.js-reg-code-form', 'Возникла ошибка попробуйте позже.');
                  alert('Возникла ошибка попробуйте позже.');

                  this.showLoader(submitButton, false);
                }
              });
            } else {
              location.href = '/personal/?newUser=Y';
            }
          }
        }
      }

      callAll(formGroupSelector, el => addClass(el, needValidateClassName));

      $events
        .add('focus blur', formFieldSelector, this.checkField)
        .delegate
        .on('submit', formSelector, this.handleForm)
        .on('input change', formGroupSelector, this.checkField)
        .on('click', perosnalItemSelector, this.checkFirstStage)
        .on('click', personalToRegistrationSelector, this.toRegistration)
        .on('click', personalToSmsSelector, this.toSms)
        .on('click', perosnalItemFormRegistrSelector, this.checkRegistrStage)
        .on('click', perosnalItemFormCodeSelector, this.checkCodeStage)
        .on('input', '.js-input-number', onlyNumbers);
      ;
    },
    onDestroy() {
      this.validator = null;

      callAll(formSelector, form => {
        form.removeAttribute('novalidate');
        if (exist(formGroupSelector, form)) callAll(formGroupSelector, el => removeClass(el, needValidateClassName), form);
        if (exist('.' + notifyClassName, form)) callAll('.' + notifyClassName, el => remove(el), form);
      });


      $events
        .remove('focus blur', formFieldSelector, this.checkField)
        .delegate
        .off('submit', formSelector, this.handleForm)
        .off('input change', formGroupSelector, this.checkField)
        .off('click', perosnalItemSelector, this.checkFirstStage)
        .off('click', personalToRegistrationSelector, this.toRegistration)
          .off('click', personalToSmsSelector, this.toSms)
        .off('click', perosnalItemFormRegistrSelector, this.checkRegistrStage)
        .off('click', perosnalItemFormRegistrSelector, this.checkCodeStage);
      ;
    },
  })
}
