import Component from '../classes/Component';
import IMask from 'imask';
import { $dom } from '../helpers/dom';

const { getAll } = $dom;

const dateMaskSelector = '.js-date-mask'

export default function dateMask() {

  return new Component({
    name: 'dateMask',
    requiredSelector: dateMaskSelector,

    onCreate() {
      this.maskOptions = {
        mask: Date,
        // min: new Date(1990, 0, 1),
        // max: new Date(2020, 0, 1),
        lazy: true
      };

      this.masks = [];

      this.masks = getAll(dateMaskSelector).map(field => IMask(field, this.maskOptions));

    },
    onInit() {

    },
    onDestroy() {
      this.masks = this.masks.filter(mask => {
        mask.destroy && mask.destroy();
        return false
      });
    }
  })
}