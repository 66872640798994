import Page from '../../classes/Page';
import { initialSteps } from './initialSteps';

const initialPage = new Page({
  name: 'initial',
  rootElementId: 'js-page-initial',

  onInit() {

    this
      .addComponent(initialSteps)
  },
});

export default initialPage;
