import Page from '../../classes/Page';

const homePage = new Page({
  name: 'home',
  rootElementId: 'js-page-home',
  
  onInit() {

    this
      // .addComponent(personalStages)
  },
});

export default homePage;
