import Component from '../classes/Component';
import IMask from 'imask';
import { $dom } from '../helpers/dom';

const { getAll } = $dom;

const phoneMaskSelector = '.js-phone-mask'

export default function phoneMask() {

  return new Component({
    name: 'phoneMask',
    requiredSelector: phoneMaskSelector,

    onCreate() {
      this.maskOptions = {
        mask: '+{7} (000) 000-00-00'
      };

      this.masks = [];

      this.masks = getAll(phoneMaskSelector).map(field => IMask(field, this.maskOptions));

    },
    onInit() {

    },
    onDestroy() {
      this.masks = this.masks.filter(mask => {
        mask.destroy && mask.destroy();
        return false
      });
    }
  })
}